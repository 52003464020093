import { Grid } from "@mui/material";
import LocalStorage from "./localStorage";
import { DisplayText } from "../features/bankDetails/components/bankAccountsContent";
import empty from "../assets/illo.png";
export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem("tata_login_user") || "";
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== "",
  };
};

export const domainName = window.location.origin;

export const bank_info =
  domainName === "https://vishakhavendors.procuregenie.com" ||
  "http://localhost:3001"
    ? 1
    : 0;
export const navitas =
  domainName === "https://vendor.navitassolar.com/" ? 1 : 0;

export function capitalizeString(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}

export const dev = false;

export const EmptyContainer = ({ text }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <br />
    <img src={empty} alt="empty" />

    <DisplayText>{text}</DisplayText>

    <br />

    <br />
  </Grid>
);

export function hasDuplicates(array1, array2, ignoreFields) {
  const array = [...array1, ...array2];
  const seen = new Set();
  const getKey = (obj) =>
    JSON.stringify(
      Object.keys(obj)
        .filter((key) => !ignoreFields.includes(key))
        .sort()
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {})
    );

  for (const obj of array) {
    const key = getKey(obj);
    if (seen.has(key)) {
      return true; // Found a duplicate
    }
    seen.add(key);
  }
  return false; // No duplicates found
}
