import {
  Box,
  CardContent,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import StarIcon from "@mui/icons-material/Star";
import React, { useState, useEffect } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getVendors,
  getVendorsemails,
  createOrder,
} from "../redux/adminOrdersSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { history } from "../../../app/history";
import { companyListForOfficer } from "../../adminTable/redux/sendLinkSlice";

const Input = styled("input")({
  display: "none",
});

function AdminOrdersContent() {
  const { companyList } = useSelector((state) => state.sendInvitationLink);
  const dispatch = useDispatch();
  const { vendors, vendoremails } = useSelector((state) => state.adminOrders);
  const [orderDoc, setOrderDoc] = useState("");
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [orderamount, setOrderamount] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Vendordetails = () => {
    dispatch(getVendors());
  };

  useEffect(() => {
    Vendordetails();
    dispatch(companyListForOfficer(""));
    // eslint-disable-next-line
  }, []);

  const handleOnchange = (e) => {
    let vendor = e.target.value;
    dispatch(getVendorsemails(vendor));
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const allowOnlyNumber = (e) => {
    let str = e.target.value;
    const regExp = /^[+-]?\d*(?:[.,]\d*)?$/;

    let status = regExp.test(str) ? "valid" : "invalid";
    status === "valid" ? setOrderamount(e.target.value) : setOrderamount("");
  };

  const handleSave = (data) => {
    setLoading(true);
    let order_details = [
      {
        vendor_id: data.vendor_id,
        type: data.type,
        order_number: data.order_number,
        order_amount: data.order_amount,
        version_number: data.version_number,
        organizational_unit: data.organizational_unit,
        project: data.project,
        vendor_email: data.vendor_email,
        cid: data.cid,
      },
    ];

    var formData = new FormData();
    formData.append("data", JSON.stringify(order_details));
    formData.append("order_document", orderDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createOrder(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/orders-list");
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create Purchase Order
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Select the purchase/work order and enter invoice details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Select Company<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="cid"
                    name="cid"
                    error={Boolean(errors?.cid)}
                    {...register("cid", {
                      required: "Please select vendor",
                    })}
                    onChange={(e) => handleOnchange(e)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select company
                      </span>
                    </MenuItem>
                    {companyList.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        <SelectSpan>{item.company_name}</SelectSpan>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{errors.cid?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Vendor<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="vendor_id"
                    name="vendor_id"
                    error={Boolean(errors?.vendor_id)}
                    {...register("vendor_id", {
                      required: "Please select vendor",
                    })}
                    onChange={(e) => handleOnchange(e)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select vendor
                      </span>
                    </MenuItem>
                    {vendors.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        <SelectSpan>
                          {item.company_name}
                          {item.fav === true ? (
                            <StarIcon
                              sx={{
                                color: "#facc15",
                                fontSize: "mediam",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </SelectSpan>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.vendor_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Type<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="type"
                    name="type"
                    {...register("type", {
                      required: "Please select type",
                    })}
                    error={Boolean(errors?.vendor_name)}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select type
                      </span>
                    </MenuItem>
                    <MenuItem value="PO">
                      <SelectSpan>Purchase Order</SelectSpan>{" "}
                    </MenuItem>
                    <MenuItem value="WO">
                      <SelectSpan>Work Order</SelectSpan>{" "}
                    </MenuItem>
                  </Select>
                  <FormHelperText error>{errors.type?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Order Number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter order number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="order_number"
                  {...register("order_number", {
                    required: "Please enter order number",
                  })}
                  error={Boolean(errors?.order_number)}
                  helperText={errors?.order_number?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>
                  Order Amount<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter order amount"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="order_amount"
                  {...register("order_amount", {
                    required: false,
                  })}
                  onChange={(e) => allowOnlyNumber(e)}
                  value={orderamount}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Version Number</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter version number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="version_number"
                  {...register("version_number", {
                    required: false,
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Organizational Unit</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter organizational unit"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="organizational_unit"
                  {...register("organizational_unit", {
                    required: false,
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Project </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter project"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="project"
                  {...register("project", {
                    required: false,
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>Vendor email</VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="vendor_email"
                    name="vendor_email"
                    {...register("vendor_email", {
                      required: false,
                    })}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select vendor email
                      </span>
                    </MenuItem>
                    {vendoremails
                      ? vendoremails.map((item, index) => (
                          <MenuItem value={item.company_email} key={index}>
                            <SelectSpan>{item.company_email}</SelectSpan>
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Documents
            </VendorCardTitle>
            <Grid container spacing={3}>
              <>
                <Grid item xs={4}>
                  <span
                    variant="outlined"
                    component="span"
                    style={{ width: "50%" }}
                  >
                    <DocumentLabels style={{ marginTop: "10px" }}>
                      Order Document<Super>*</Super>
                    </DocumentLabels>
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(errors?.order_document)}
                  >
                    {orderDoc !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={orderDoc.name}
                            value={orderDoc.name}
                            name="chips"
                          />
                          <HighlightOffIcon
                            sx={{ color: "#4945FF", paddingBottom: "2px" }}
                            fontSize="medium"
                            onClick={(e) => setOrderDoc("")}
                          />
                        </span>
                      </>
                    ) : (
                      <label htmlFor="order_document">
                        <Input
                          {...register("order_document", {
                            required: "Please upload order document",
                          })}
                          accept="*"
                          id="order_document"
                          onChange={(e) => setOrderDoc(e.target.files[0])}
                          type="file"
                        />
                        <StyledOutlineButton
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {orderDoc === "" ? (
                      <FormHelperText error>
                        {errors.order_document?.message}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </>
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
          </CardContent>
        </StyledCard>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />

          {/* <LoadingButton
          size="small"
          color="secondary"
          onClick={handleClick}
          loading={loading}
          loadingPosition="start"
          variant="contained"
        >
          Validate
        </LoadingButton> */}
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Upload Order
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default AdminOrdersContent;
