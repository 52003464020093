import React from "react";
import { NavLink } from "react-router-dom";

function UserPrivilegesContent() {
  return (
    <>
      <li>
        <NavLink to="/app/user-privileges/pr-creation" end={true}>
          Purchase Requisition Creation
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="/app/user-privileges/po-creation" end={true}>
          Purchase Order Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/schedule-creation" end={true}>
          Schedule Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/asn-creation" end={true}>
          ASN Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/grn-creation" end={true}>
          GRN Creation
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/user-privileges/invoice-creation" end={true}>
          Invoice Creation
        </NavLink>
      </li> */}
    </>
  );
}

export default UserPrivilegesContent;
