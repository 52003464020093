import React from "react";
import { ApprovedContentForOfficer } from "./approved/ApprovedContent";
import { PendingContentForOfficer } from "./pending/PendingContent";
import { ReturnedContentForOfficer } from "./returned/ReturnedContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovedList,
  getPendingList,
  getReturnedList,
  vendorCount,
} from "../redux/adminTableSlice";
import SwipeableViews from "react-swipeable-views";
import { CustomBox, CustomTab } from "./adminTableContent";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Tab, Box, Badge, Grid } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CustomBox
          sx={{
            pt: 3,
            backgroundColor: "#f5f7f9",
          }}
        >
          <div>{children}</div>
        </CustomBox>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export const OfficerAdminTableContent = ({ search, clicked, setClicked }) => {
  const {
    approved,
    returned,
    pending,
    vendorCount: count,
  } = useSelector((state) => state.pendinglist);

  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [prevCall, setPrevCall] = React.useState({});
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  let payload = {
    search,
    page,
    per_page,
    value,
  };

  const getALLVendorDetails = () => {
    payload = { value, search, page, per_page };
    if (value === 0) {
      if (prevCall) {
        if (prevCall?.value !== value) {
          setPage(1);
        }
      }
      dispatch(getPendingList(payload));
    }
    if (value === 1) {
      if (prevCall) {
        if (prevCall?.value !== value) {
          setPage(1);
        }
      }
      dispatch(getApprovedList(payload));
    }
    if (value === 2) {
      if (prevCall) {
        if (prevCall?.value !== value) {
          setPage(1);
        }
      }
      dispatch(getReturnedList(payload));
    }
    // dispatch(getALLVendorAdmin(payload)).then(() => {
    setPrevCall(payload);
    dispatch(vendorCount());
    // });
    setClicked(false);
  };

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  React.useEffect(() => {
    getALLVendorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, page, per_page, clicked]);
  return (
    <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
      <CustomTab
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          label={
            <Grid spacing={1} className="d-flex gap-4">
              <Grid md={8}>Pending</Grid>
              <Grid md={4}>
                <Badge
                  color="info"
                  badgeContent={count?.pendingCount}
                  showZero
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  max={100000000000}
                ></Badge>
              </Grid>
            </Grid>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Grid spacing={1} className="d-flex gap-4">
              <Grid md={8}>Approved</Grid>
              <Grid md={4}>
                <Badge
                  color="info"
                  badgeContent={count?.approvedCount}
                  showZero
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  max={100000000000}
                ></Badge>
              </Grid>
            </Grid>
          }
          {...a11yProps(1)}
        />
        <Tab
          label={
            <Grid spacing={1} className="d-flex gap-4">
              <Grid md={8}>returned</Grid>
              <Grid md={4}>
                <Badge
                  color="info"
                  badgeContent={count?.returnedCount}
                  showZero
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  max={100000000000}
                ></Badge>
              </Grid>
            </Grid>
          }
          {...a11yProps(2)}
        />
      </CustomTab>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PendingContentForOfficer
            pending={pending}
            setPage={setPage}
            setPer_page={setPer_page}
            page={page}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ApprovedContentForOfficer
            approved={approved}
            setPage={setPage}
            setPer_page={setPer_page}
            page={page}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ReturnedContentForOfficer
            returned={returned}
            setPage={setPage}
            setPer_page={setPer_page}
            page={page}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
