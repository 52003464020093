export function labels(tenant_id, key) {
  const LablesJson = [
    { key: "sidebarCompany", label: "Companies", tenant_id: 1 },
    { key: "sidebarCompany", label: "Companies", tenant_id: 2 },
    { key: "sidebarCompany", label: "Companies", tenant_id: 3 },
    { key: "sidebarCompany", label: "Companies", tenant_id: 4 },
    { key: "sidebarCompany", label: "Departments", tenant_id: 5 },
    { key: "addAndTable", label: "Company", tenant_id: 1 },
    { key: "addAndTable", label: "Company", tenant_id: 2 },
    { key: "addAndTable", label: "Company", tenant_id: 3 },
    { key: "addAndTable", label: "Company", tenant_id: 4 },
    { key: "addAndTable", label: "Department", tenant_id: 5 },
  ];

  const label = LablesJson.find(
    (label) => label.tenant_id === tenant_id && label.key === key
  );
  return label.label;
}
