import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import CompanyContent from "./components/companyContent";
import { Companies } from "../../CONSTANTS";
export default function Company() {
  return (
    <div>
      <TitleHolder>{Companies}</TitleHolder>
      <StepperContentHolder>
        <CompanyContent />
      </StepperContentHolder>
    </div>
  );
}
