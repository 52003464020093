import React from "react";
import { NavLink } from "react-router-dom";

function ConfigurationListItems() {
  return (
    <>
      <li>
        <NavLink to="/app/user-privileges" end={true}>
          Define user privileges
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/workflow-steps" end={true}>
          Create workflow and define its steps
        </NavLink>
      </li>
    </>
  );
}

export default ConfigurationListItems;
