import apiClient from "../../../utilities/apiClient";

export const company = (payload) => {
  return apiClient.post(`${apiClient.Urls.company}`, payload, true);
};

export const getCompany = (page, per_page, search) => {
  return apiClient.get(
    `${apiClient.Urls.company_list}?page=${page}&per_page=${per_page}&search=${
      search || ""
    }`,
    "",
    true
  );
};

export const getCompanyDetails = (payload) => {
  return apiClient.get(`${apiClient.Urls.company}/${payload}`, "", true);
};
export const getSubCompaniesDropdown = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.subCompanyDropdown}?role_name=${payload}`,
    "",
    true
  );
};

export const getCompanyOfficers = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.company_officer}/${payload}`,
    "",
    true
  );
};

export const ActiveInactiveCompanies = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.activeInactiveCompany}`,
    payload,
    true
  );
};
