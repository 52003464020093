import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { styled, useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApprovedContent from "./approved/ApprovedContent";
import PendingContent from "./pending/PendingContent";
import ReturnedContent from "./returned/ReturnedContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getALLVendorAdmin,
  initiatedVendorDataThunk,
  vendorCount,
} from "../redux/adminTableSlice";
import { Badge, Grid } from "@mui/material";
import InitiatedContent from "./initiated/initiatedContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CustomBox
          sx={{
            pt: 3,
            backgroundColor: "#f5f7f9",
          }}
        >
          <div>{children}</div>
        </CustomBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ search, clicked, setClicked }) {
  const {
    VendorData,
    pagination,
    initiatedPagination,
    vendorCount: count,
  } = useSelector((state) => state.pendinglist);
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [prevCall, setPrevCall] = React.useState({});
  const [registerFilter, setRegisterFilter] = React.useState(true);

  const dispatch = useDispatch();

  const getALLVendorDetails = () => {
    let status;
    value === 1
      ? (status = "approved")
      : value === 2
      ? (status = "rejected")
      : value === 0
      ? (status = "pending")
      : (status = "initiated");
    if (prevCall) {
      if (prevCall?.status !== status) {
        setPage(1);
      }
    }
    let payload = { status, registerFilter, search, page, per_page };
    setRegisterFilter(true);
    if (status === "initiated") {
      dispatch(initiatedVendorDataThunk(payload)).then(() => {
        setPrevCall(payload);
      });
    } else {
      dispatch(getALLVendorAdmin(payload)).then(() => {
        setPrevCall(payload);
      });
      setClicked(false);
    }
    dispatch(vendorCount());
  };

  React.useEffect(() => {
    getALLVendorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, clicked, page, per_page]);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <>
      <Box sx={{ boxShadow: "0px 20px 30px rgba(52, 173, 237, 0.2)" }}>
        <CustomTab
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label={
              <Grid spacing={1} className="d-flex gap-3">
                <Grid md={8}>Pending</Grid>
                <Grid md={4}>
                  <Badge
                    color="info"
                    badgeContent={count?.pendingCount}
                    showZero
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    max={100000000000}
                  ></Badge>
                </Grid>
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid spacing={1} className="d-flex gap-4">
                <Grid md={8}>Approved</Grid>
                <Grid md={4}>
                  <Badge
                    color="info"
                    badgeContent={count?.approvedCount}
                    showZero
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    max={100000000000}
                  ></Badge>
                </Grid>
              </Grid>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid spacing={1} className="d-flex gap-3">
                <Grid md={8}>returned</Grid>
                <Grid md={4}>
                  <Badge
                    color="info"
                    badgeContent={count?.returnedCount}
                    showZero
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    max={100000000000}
                  ></Badge>
                </Grid>
              </Grid>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Grid spacing={1} className="d-flex gap-3">
                <Grid md={8}>initiated</Grid>
                <Grid md={4}>
                  <Badge
                    color="info"
                    badgeContent={0}
                    showZero
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    max={100000000000}
                  ></Badge>
                </Grid>
              </Grid>
            }
            {...a11yProps(3)}
          />
        </CustomTab>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PendingContent
              pending={VendorData}
              setPage={setPage}
              setPer_page={setPer_page}
              per_page={per_page}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <ApprovedContent
              approved={VendorData}
              setPage={setPage}
              setPer_page={setPer_page}
              pagination={pagination}
              page={page}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ReturnedContent
              returned={VendorData}
              setPage={setPage}
              setPer_page={setPer_page}
              pagination={pagination}
              page={page}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <InitiatedContent
              setPage={setPage}
              setPer_page={setPer_page}
              pagination={initiatedPagination}
              page={page}
            />
            {/* <ReturnedContent returned={VendorData} /> */}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </>
  );
}

export const CustomBox = styled(Box)`
  background-color: #f5f7f9;
  /*   box-shadow: 0px 0px 20px rgba(52, 173, 237, 0.2); */
  border-radius: 5px;
  /* filter: drop-shadow(0px 0px 0px 20px rgba(52, 173, 237, 0.2)); */
`;

export const CustomTab = styled(Tabs)`
  background-color: #f5f7f9;
  .css-11yukd5-MuiTabs-indicator {
    background-color: #4059e5;
  }
`;
